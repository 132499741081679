@keyframes layout1-anim1 {
  from {
    transform: scale(0.97);
    opacity: 0;
  }
}
@keyframes layout1-anim2 {
  from {
    transform: scale(0.97);
    opacity: 0;
  }
}
