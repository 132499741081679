@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  height: 100%;
}
html,
body {
  height: 100%;
}
body {
  --dimmed: rgba(128, 128, 128, 0.5);
  --dimmest: rgba(128, 128, 128, 0.15);
  --primary: #00897b;
  --primaryd: #005f55;
  --primaryl: #00bca9;
  --secondary: #80cbc4;
  --secondaryd: #6eaea8;
  --paper: #fafaf9;
  --lines: #cfd8dc;
  margin: 0;
  background: var(--paper);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:active {
  opacity: 0.8;
}

.error-icon::before {
  content: "\026a0";
  padding-right: 0.5em;
}

.flag {
  font-family: "Noto Color Emoji";
}

th {
  font-weight: inherit;
  text-align: inherit;
}

.stats-table {
  @apply border border-collapse;
}
@-moz-document url-prefix() {
  /*https://bugzilla.mozilla.org/show_bug.cgi?id=1658119*/
  .stats-table thead th {
    box-shadow: 0 0 0 0.5px var(--primaryl);
  }
}
.stats-table thead {
  @apply sticky top-0 z-10 text-white bg-primary;
}
.stats-table td,
.stats-table th {
  @apply px-1 py-0.5 border border-lines;
}
.stats-table th {
  @apply border-primaryl;
}
.stats-table tr:nth-child(2n) > td {
  @apply bg-black/5;
}

input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

label.ondrag {
  transition: transform 0.3s cubic-bezier(0.5, 0, 0, 1.5);
  transform: scale(1.1);
}

.chat-msg-enter {
  opacity: 0;
  transform: scale(0.5);
}
.chat-msg-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 0.5s, transform 0.5s;
}

.chat-thread-enter {
  animation: chat-thread 0.5s ease-in-out;
}

@keyframes chat-thread {
  50% {
    transform: scale(1.2);
    background-color: var(--secondary);
  }
}

.pin-chg {
  animation: pin-chg 1.5s ease-out;
}

@keyframes pin-chg {
  from {
    opacity: 0;
  }
}
