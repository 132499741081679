.bar {
  width: 100%;
  align-self: flex-start;
  box-sizing: border-box;
  height: 3px;
  background: linear-gradient(
      90deg,
      rgba(255 255 255 / 0.3) 40%,
      var(--primary) 41%,
      var(--primary) 59%,
      rgba(255 255 255 / 0.3) 60%
    )
    repeat-x;
  background-size: 200%;
  animation: loading-bar 1.5s 0.5s linear infinite;
}

@keyframes loading-bar {
  from {
    background-position: 150% 0;
  }
  to {
    background-position: -50% 0;
  }
}
